import classNames from "classnames";
import get from "lodash/get";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { baseEnvironmentConfig } from "@/api/constants";
import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import {
  ACDL_LEAD_FLOW_TRACKING,
  LEAD_FLOW_CATEGORY_TYPES,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { CONTENT_TYPES, LOCAL_STORAGE_KEYS } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";
import useAcdlCustomEvent from "@/hooks/useAcdlCustomEvent";
import useWebView from "@/hooks/useWebView";

import styles from "./Layout.module.scss";

const GlobalFooter = dynamic(() => import("@/components/GlobalFooter"));
const GlobalHeader = dynamic(() => import("@/components/GlobalHeader"));
const NotificationBanner = dynamic(
  () => import("@/components/NotificationBanner")
);
const PersonalizationBanner = dynamic(
  () => import("@/components/PersonalizationBanner")
);

const Chat = dynamic(() => import("@/components/chat"));
const SEOandAnalytics = dynamic(() => import("@/components/seo-and-analytics"));

const Layout = ({
  chatEnabled = true,
  children,
  club,
  facilityData,
  hideFooter = false,
  layoutBlock = "start",
  mainClassName,
  metaData = {},
  modules = [],
  moduleSpacing,
  navigation = undefined,
  notificationBanner,
  page = "",
  pageSlug,
  showSimpleHeaderTagline = true,
  theme = "dark",
  useSimpleHeader = false,
}) => {
  const context = useContext(UserContext);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const isWebView = useWebView();

  const { session } = context;

  const checkIsEngageOrNonEngage = () => {
    const moduleForm = modules?.filter((module) => {
      const {
        contentType: {
          sys: { id },
        },
      } = module.sys;

      return id === CONTENT_TYPES.MODULE_FORM;
    });

    const formType = get(moduleForm, "[0].fields.formType", "lead");

    const leadCategory =
      formType === "lead"
        ? LEAD_FLOW_CATEGORY_TYPES.ENGAGE
        : LEAD_FLOW_CATEGORY_TYPES.NON_ENGAGE;

    return leadCategory;
  };

  // scroll event for ACDL when the  lead flow is in viewport
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_LEAD_FLOW,
    ACDL_LEAD_FLOW_TRACKING.leadScrollEvent(),
    'data-leadview="lead-view"'
  );

  // Direct Response Hero Lead
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_LEAD_FLOW,
    ACDL_LEAD_FLOW_TRACKING.leadScrollEvent(),
    'data-leadview="lead-view-direct-response"',
    "load"
  );

  // scroll event for ACDL when the  join flow is in viewport
  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_MEMBERSHIP_OPTION,
    ACDL_JOIN_FLOW_TRACKING.joinScrollEvent(
      page === baseEnvironmentConfig.pageNames.clubDetail
    ),
    'data-joinview="join-view"'
  );

  // scroll event for ACDL when the custom form is in viewport
  const leadCategory = checkIsEngageOrNonEngage();

  useAcdlCustomEvent(
    setNewDataLayer,
    modules,
    CONTENT_TYPES.MODULE_FORM,
    ACDL_LEAD_FLOW_TRACKING.leadScrollEvent(leadCategory),
    'data-leadview="moduleForm"'
  );

  return (
    <>
      <SEOandAnalytics
        clubData={club}
        facilityData={facilityData}
        metaData={metaData}
      />

      {navigation && !isWebView && (
        <GlobalHeader
          showSimpleHeaderTagline={showSimpleHeaderTagline}
          useSimpleHeader={useSimpleHeader}
        />
      )}

      {notificationBanner && !session.user && (
        <NotificationBanner
          club={club}
          data={notificationBanner}
          stickyOffSet={page}
        />
      )}

      {!notificationBanner && !session.user && (
        <PersonalizationBanner
          campaignId="data-vwo-rrr12"
          excludedUrl="/personaltraining"
          linkText="Get Started"
          linkUrl="promotion/why-equinox-personal-training"
          text="Accelerate results and maximize performance with Coaching at Equinox."
        />
      )}

      {!notificationBanner && !session.user && (
        <PersonalizationBanner
          campaignId="data-vwo-rrr109"
          excludedUrl="/clubs"
          linkText="Click Here"
          linkUrl="inquiry/schedule-your-visit/[facilityId]"
          linkUrlPlaceholder="facilityId"
          localStorageKey={LOCAL_STORAGE_KEYS.CLUB_PAGE_VISITED}
          text="Ready to schedule your visit at Equinox [clubName]?"
          textPlaceholder="clubName"
        />
      )}

      {chatEnabled &&
        process.env.NEXT_PUBLIC_DISABLE_FEATURE_SALESFORCE_CHAT !== "true" && (
          <Chat pageName={page} session={session} />
        )}

      <main
        className={classNames(styles.main, mainClassName)}
        data-layout-block={layoutBlock}
        data-module-spacing={moduleSpacing}
        data-page={pageSlug}
        data-theme={theme}
        id="main-content"
      >
        {children}
      </main>

      {!hideFooter && !isWebView && <GlobalFooter />}
    </>
  );
};

Layout.propTypes = {
  chatEnabled: PropTypes.bool,
  children: PropTypes.node,
  club: PropTypes.object,
  facilityData: PropTypes.object,
  hideFooter: PropTypes.bool,
  layoutBlock: PropTypes.string,
  mainClassName: PropTypes.string,
  metaData: PropTypes.shape({
    allowSearchEngineIndex: PropTypes.bool,
    description: PropTypes.string,
    image: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    keywords: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  moduleSpacing: PropTypes.string,
  modules: PropTypes.array,
  navigation: PropTypes.object,
  notificationBanner: PropTypes.object,
  page: PropTypes.string,
  pageSlug: PropTypes.string,
  showSimpleHeaderTagline: PropTypes.bool,
  theme: PropTypes.string,
  useSimpleHeader: PropTypes.bool,
};

export default Layout;
